import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translateOptions } from "../../../i18n";
import { withTranslation, useTranslation } from "react-i18next";
import googlePlacesService from "common/services/googlePlaces";
import {
  setSearchHistory,
  getSearchHistory,
} from "common/helpers/locationHistory";
import analyticsPubSub, {
  ANALYTICS_EVENTS,
} from "common/services/analyticsPubSub";
import { withStyles } from "@material-ui/styles";
import {
  setLocationDialog,
  setSelectedAddress,
  setSubLocality,
  unSetLocationDialog,
} from "common/store/actions/ui";
import { Dialog } from "@material-ui/core";
import CustomLinearProgress from "../../custom-linear-progress/index.component";
import MultipleStoreDialog from "../../multiple-store-dialog/index.component";
import storeStatus from "common/helpers/storeHelper";
import configSelector from "common/store/selectors/configSelector";
import pageSelector from "common/store/selectors/pageSelector";
import { useDialog } from "../../../context/DialogContext";
import upsdkSelector from "common/store/selectors/upsdkSelector";
import CrossIcon from "../../../assets/icons/cross.icon";
import {
  increaseBrightness,
  bright_90,
  bright_70,
} from "common/helpers/adjustColor";
import upsdkService from "common/services/upsdkService";
import LocationCircleIcon from "../../../assets/icons/location-circle.icon";
import HomeIconOutlined from "../../../assets/icons/home-outlined.icon";
import ArrowIcon from "../../../assets/icons/arrow.icon";
import LocationIcon from "../../../assets/icons/location.icon";
import OfficeIcon from "../../../assets/icons/office.icon";
import PinOutlinedIcon from "../../../assets/icons/pin-outlined.icon";
import HistoryIcon from "../../../assets/icons/history.icon";
import SearchIcon from "../../../assets/icons/search.icon";
import Typography from "../../typography/index.component";
import DeliveryModeWidget from "../../../views/checkout/delivery-mode-widget/index.component";
import FulFilmentTypeSelector from "../../fulfilment-type-selector/index.component";
import useRouteHook from "../../../hooks/useRoute.hook";
import { BaseContext } from "../../../context/BaseContext";
import {
  COLOR_GUNMETAL_GRAY,
  COLOR_QUICK_SILVER,
  COLOR_SLATE_GRAY,
} from "../../../constants/colors.constants";
import ScreenHelper from "../../../helpers/screen.helper";
import Divider from "@urbanpiper-engineering/meraki-components/dist/Atoms/Divider";
import PickupStoreIcon from "../../../assets/icons/pickup-store.icon";
import Loader from "../../loader/index.component";
import graphqlService from "common/services/graphql/graphqlService";
import debounce from "common/helpers/debounce";
import { MINIMUM_SEARCH_STRING_LENGTH } from "../../../constants/enums.constants";

import "./index.component.scss";

let placeDetails = null;

function SearchWidget({
  setSubLocality,
  searchQuery,
  setSearchQuery,
  addresses,
  places,
  landingPage,
  config,
  handleClose,
  cart,
  fulfilmentType = null,
  upsdk,
  setOpen,
  children,
  setSelectedAddress,
  onLocationSelect,
  checkout,
  selectedStore,
  fulfilmentCallback,
  isDarkHeader,
  headerColorConfig,
  deliverNowLaterCheckout,
  riderAvailabilityCheck,
  deliverModeWidgetError,
  fulfilmentTypeLocal,
}) {
  const { showDialog, hideDialog } = useDialog();
  const { i18n, t } = useTranslation();
  const { historyPush, pathname } = useRouteHook();
  const isCheckoutScreen = ScreenHelper.isCheckoutScreen(pathname);

  const { menuRoute, isMobileView } = useContext(BaseContext);
  const secondaryTextColor = configSelector.getSecondaryTextColor({ config });
  const primaryTextColor = configSelector.getPrimaryTextColor({ config });
  const primaryColor = configSelector.getPrimaryColor({ config });
  const secondaryColor = configSelector.getSecondaryColor({ config });
  const tertiaryColor = configSelector.getTertiaryColor({ config });
  const storeLocatorPageConfig = pageSelector.getStoreLocatorPage({ config });
  const maxPreOrderDate = configSelector.getMaxPreOrderDate({ config });
  const upfrontFulfillmentTimePromptFlag =
    configSelector.getUpfrontFulfillmentTimePromptFlag({ config }) &&
    maxPreOrderDate !== -1;
  const bizInfo = upsdkSelector.getBizInfo({ upsdk });
  const savedLocations = getSearchHistory();
  const [fetchLocationProgress, setFetchLocationProgress] = useState(false);
  const [nearestStores, setNearestStores] = useState([]);
  const [multipleStoreDialog, setMultipleStoreDialog] = useState(false);
  const [deliverNowLater, setDeliverNowLater] = useState(
    deliverNowLaterCheckout || false,
  );
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [selectedType, setSelectedType] = useState("deliver_now");
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [pickupStores, setPickupStores] = useState([]);
  const [pickupStoresLoading, setPickupStoresLoading] = useState(true);
  const ffMode = fulfilmentTypeLocal || fulfilmentType;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getStoresListDebounced = useCallback(
    debounce(async (lat, lng, fulfilment_mode, keyword) => {
      return getStoresList(lat, lng, fulfilment_mode, keyword);
    }, 500),
    [], // Empty dependency array to ensure the function is only created once
  );

  const getStoresList = async (lat, lng, fulfilment_mode, keyword) => {
    try {
      setPickupStoresLoading(true);
      const response = await graphqlService.getStoresList(
        lat,
        lng,
        fulfilment_mode,
        keyword,
      );
      setPickupStores(response);
    } catch {
      setPickupStores([]);
    }
    setPickupStoresLoading(false);
  };

  useEffect(() => {
    getStoresListDebounced(null, null, ffMode, searchQuery);
    // get stores on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      ffMode === "pickup" &&
      (searchQuery?.length > MINIMUM_SEARCH_STRING_LENGTH || searchQuery === "")
    ) {
      getStoresListDebounced(null, null, ffMode, searchQuery);
    }
  }, [ffMode, getStoresListDebounced, searchQuery]);

  const handleGoToStoreLocatorClick = () => {
    handleClose();
    unSetLocationDialog();
    historyPush("/store-locator");
  };

  const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = increaseBrightness(
      primaryColor,
      bright_90,
    );
  };

  const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = "white";
  };

  const selectDeliverableStore = (lat, lng, subLocality) => {
    return new Promise(async function (resolve, reject) {
      upsdkService
        .getDeliverableStores(lat, lng, subLocality, ffMode)
        .then((response) => {
          if (
            response.store &&
            response.optional_stores &&
            response.optional_stores.length
          ) {
            toggleMultipleStoreDialog();
            if (!landingPage) {
              const eventObj = {
                lat: lat,
                lng: lng,
                stores: response.optional_stores,
                search_query: searchQuery,
                number_of_stores: response.optional_stores.length,
              };
              analyticsPubSub.publish(
                ANALYTICS_EVENTS.MULTIPLE_DELIVERY_STORES,
                eventObj,
              );
            }
            const data = [response.store, ...response.optional_stores];
            setNearestStores(data);
            setFetchLocationProgress(false);
            //handled in multi store modal button click callback
            return;
          }
          if (!response.store) {
            showDialog({
              title: t("header.storeNotDeliverableHeading"),
              description: bizInfo ? bizInfo.msg_no_stores_nearby : "",
              buttonText: t("header.storeNotDeliverableConfirm"),
              buttonColor: primaryColor,
              onClickCallback: () => {
                hideDialog();
              },
            });
            reject("");
          } else {
            let sStatus = storeStatus(response.store, bizInfo);
            if (sStatus && sStatus.closed && !sStatus.futureOrders) {
              showDialog({
                title: sStatus.title,
                description: sStatus.message,
                buttonText: t("header.storeNotDeliverableConfirm"),
                buttonColor: primaryColor,
                onClickCallback: () => {
                  hideDialog();
                },
              });
            } else {
              resolve(response.store);
              upsdkService.selectStore(response.store, ffMode);
            }
          }
        })
        .finally(() => {
          setFetchLocationProgress(false);
        });
    });
  };

  const handleSelectAddress = (address) => {
    validateStoreChanges().then(() => {
      const { lat, lng } = address;
      placeDetails = address;
      selectDeliverableStore(lat, lng, address.sub_locality).then((res) => {
        handleDeliveryStoreComplete();
      });
    });
  };

  const validateStoreChanges = () => {
    return new Promise(async function (resolve, reject) {
      if (cart.items.length) {
        showDialog({
          title: t("header.clearCartHeading"),
          description: t("header.clearCartDescription"),
          buttonText: t("header.clearCartConfirm"),
          buttonColor: primaryColor,
          onClickCallback: () => {
            upsdkService.clearCart();
            hideDialog();
            resolve("success");
          },
        });
      } else {
        resolve("success");
      }
    });
  };

  const handleFetchCurrentLocationClick = () => {
    if (navigator.geolocation) {
      setFetchLocationProgress(true);
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    }
  };

  const handleSuccess = async (response) => {
    if (response && response.coords) {
      const latitude = response.coords.latitude;
      const longitude = response.coords.longitude;
      try {
        placeDetails = await googlePlacesService.getSubLocality(
          latitude,
          longitude,
        );
        if (checkout) {
          onLocationSelect(placeDetails);
          return;
        }
      } catch (error) {
        showDialog({
          title: t("common.wentWrongTitle"),
          description: t("common.loadingError", {
            componentName: "searching location",
          }),
          buttonText: t("common.ok"),
          buttonColor: primaryColor,
          onClickCallback: () => {
            hideDialog();
          },
        });
        setFetchLocationProgress(false);
        return;
      }

      selectDeliverableStore(latitude, longitude, "NA")
        .then((deliverableStoreDetails) => {
          handleDeliveryStoreComplete();
        })
        .catch((e) => {
          setFetchLocationProgress(false);
        });
      setFetchLocationProgress(false);
    }
  };

  const handleError = (response) => {
    setFetchLocationProgress(false);
  };

  const handleSelectSubLocality = (locationAddress) => {
    if (checkout) {
      onLocationSelect(locationAddress);
      return;
    }
    setFetchLocationProgress(true);
    validateStoreChanges().then(() => {
      setSearchHistory(locationAddress);

      const { place_id } = locationAddress;
      googlePlacesService.getDetails(place_id).then((placeDetailRes) => {
        placeDetails = placeDetailRes;
        selectDeliverableStore(
          placeDetailRes.lat,
          placeDetailRes.lng,
          placeDetailRes.formatted_address || "NA",
        )
          .then((res) => {
            handleDeliveryStoreComplete();
          })
          .catch((e) => {
            // upsdkService.removeStore();
          });
      });
    });
  };

  const handleSelectStore = (storeDetails) => {
    return validateStoreChanges().then(() => {
      return new Promise(async function (resolve, reject) {
        let sStatus = storeStatus(storeDetails, bizInfo);
        if (sStatus.closed && !sStatus.futureOrders) {
          showDialog({
            title: sStatus.title,
            description: sStatus.message,
            buttonText: t("header.storeNotDeliverableConfirm"),
            buttonColor: primaryColor,
            onClickCallback: () => {
              hideDialog();
            },
          });
          reject(sStatus);
        } else {
          upsdkService.selectStore(storeDetails, ffMode);
          // if (setOpen) {
          //   setOpen(false);
          // }

          resolve("success");
        }
      });
    });
  };

  const handleDeliveryStoreComplete = () => {
    if (ffMode !== "pickup") {
      if (placeDetails.id) {
        setSelectedAddress(placeDetails);
      } else {
        setSelectedAddress(null);
      }

      setSubLocality(placeDetails);
    }

    setSearchQuery("");
    if (upfrontFulfillmentTimePromptFlag) {
      setDeliverNowLater(true);
    } else {
      setOpen(false);
    }

    setFetchLocationProgress(false);

    if (landingPage) {
      historyPush(menuRoute);
    }
  };

  const toggleMultipleStoreDialog = () => {
    setMultipleStoreDialog(!multipleStoreDialog);
  };

  const handleMultiStoreButtonClick = (storeDetails) => {
    toggleMultipleStoreDialog();
    handleSelectStore(storeDetails).then((res) => {
      handleDeliveryStoreComplete();
    });
  };

  const handleSetDeliveryTime = (dateTime) => {
    setDeliveryTime(dateTime);
  };

  const handleSelectSlot = (slot) => {
    setSelectedSlot(slot);
  };

  const handleGoBack = () => {
    if (deliverNowLater) {
      setDeliverNowLater(false);
      setMultipleStoreDialog(true);
    } else {
      setMultipleStoreDialog(false);
    }
  };

  const closeDesktopDialog = () => {
    setMultipleStoreDialog(false);
    if (!isCheckoutScreen) {
      setDeliverNowLater(false);
    }
  };

  const handleSelectPickupStore = async (storeId = null) => {
    if (!storeId) return;
    setPickupStoresLoading(true);
    try {
      const hydratedStore = await upsdkService.getStoreById(storeId, false);
      if (hydratedStore) {
        handleSelectStore(hydratedStore).then((res) => {
          handleDeliveryStoreComplete();
        });
      }
    } catch (error) {
      console.log(error);
    }
    setPickupStoresLoading(false);
  };

  const SubLocalityItem = ({ data, showIcon }) => (
    <div
      className="address-item-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={data.id}
      onClick={() => handleSelectSubLocality(data)}
    >
      {showIcon && (
        <div className="address-icon">
          <HistoryIcon
            className="field-icon"
            fill={COLOR_GUNMETAL_GRAY}
            size={{
              width: "18px",
              height: "18px",
            }}
          />
        </div>
      )}
      <div className="address-text">
        <Typography
          variant="h4"
          weight="regular"
          className="address-title"
          fontColor={primaryTextColor}
        >
          {data.structured_formatting.main_text || ""}
        </Typography>
        <Typography
          variant="h4"
          weight="regular"
          className="address"
          fontColor={secondaryTextColor}
        >
          {data.description}
        </Typography>
      </div>
    </div>
  );

  const EmptySearchState = ({ placeholderText }) => (
    <div className="empty-search-state">
      <div className="icon-wrapper">
        <SearchIcon className="search-icon" fill="#ffffff" />
      </div>
      <Typography variant="h3" weight="regular" fontColor={primaryTextColor}>
        {placeholderText}
      </Typography>
    </div>
  );

  const AddressItem = ({ address }) => (
    <div
      className="address-item-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={address.id}
      onClick={() => handleSelectAddress(address)}
    >
      <div className="address-icon">
        {address.tag === "home" ? (
          <HomeIconOutlined className="field-icon" />
        ) : address.tag === "work" ? (
          <OfficeIcon className="field-icon" />
        ) : (
          <PinOutlinedIcon className="field-icon" />
        )}
      </div>
      <div className="address-text">
        <Typography
          variant="h3"
          weight="regular"
          className="address-title"
          fontColor={primaryTextColor}
        >
          {address.tag}
        </Typography>
        <Typography
          variant="h3"
          weight="regular"
          className="address"
          fontColor={secondaryTextColor}
        >
          {address.address_1}
        </Typography>
      </div>
    </div>
  );

  const PickupStoreItem = ({ store }) => {
    return (
      <div
        onClick={() => {
          handleSelectPickupStore(store?.biz_location_id);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="pickup-store-item"
      >
        <div className="store-icon">
          <PickupStoreIcon />
        </div>
        <div className="pickup-store-content">
          <Typography
            variant="h4"
            weight="regular"
            className="store-name"
            fontColor={primaryTextColor}
          >
            {store.name}
          </Typography>
          <Typography
            variant="h4"
            weight="regular"
            className="store-address"
            fontColor={secondaryTextColor}
          >
            {store.address}
          </Typography>
        </div>
      </div>
    );
  };

  const LinearProgressStyles = () => ({
    colorPrimary: {
      backgroundColor: primaryColor,
    },
    barColorPrimary: {
      backgroundColor: increaseBrightness(primaryColor, bright_70),
    },
  });

  const ColoredLinearProgress =
    withStyles(LinearProgressStyles)(CustomLinearProgress);

  const multipleScreensBody = (
    <>
      {(multipleStoreDialog || deliverNowLater) && !deliverNowLaterCheckout && (
        <>
          <div className="dialog-header">
            {!checkout && (
              <span onClick={handleGoBack} className="arrow-back">
                ❮
              </span>
            )}

            <div style={{ flexGrow: "2" }}>
              <Typography
                variant={isMobileView ? "h2" : "h1"}
                weight={isMobileView ? "bold" : "semiBold"}
                className="form-heading"
                fontColor="#182135"
              >
                {multipleStoreDialog
                  ? t("header.chooseYourPreferredStore")
                  : selectedStore?.name}
              </Typography>
              {multipleStoreDialog && (
                <Typography
                  variant="h4"
                  weight="regular"
                  className="form-sub-heading"
                  fontColor="#626264"
                >
                  {t("header.storesNearYou", {
                    storeCount: nearestStores.length,
                  })}
                </Typography>
              )}
            </div>

            {!isMobileView && (
              <CrossIcon
                fill="#A3A3A4"
                size={{ width: 16, heigth: 16 }}
                className="close-dialog-icon"
                onClickCallback={closeDesktopDialog}
              />
            )}
          </div>
          <Divider variant="horizontal" size="99%" color={COLOR_QUICK_SILVER} />
        </>
      )}

      {multipleStoreDialog ? (
        <MultipleStoreDialog
          data={nearestStores}
          primaryColor={primaryColor}
          open={multipleStoreDialog}
          primaryTextColor={primaryTextColor}
          secondaryTextColor={secondaryTextColor}
          toggleMultipleStoreDialog={toggleMultipleStoreDialog}
          buttonClickCallback={handleMultiStoreButtonClick}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
        />
      ) : deliverNowLater ? (
        <DeliveryModeWidget
          bizInfo={bizInfo}
          selectedStore={selectedStore}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          tertiaryColor={tertiaryColor}
          fulfilmentType={ffMode}
          deliveryTime={deliveryTime}
          primaryTextColor={primaryTextColor}
          secondaryTextColor={secondaryTextColor}
          setDeliveryTimeCallback={handleSetDeliveryTime}
          setSelectedSlotCallback={handleSelectSlot}
          selectedType={selectedType}
          selectedSlot={selectedSlot}
          setSelectedType={setSelectedType}
          setOpen={setOpen}
          landingPage={landingPage}
          closeDesktopDialog={closeDesktopDialog}
          riderAvailabilityCheck={riderAvailabilityCheck}
          deliverModeWidgetError={deliverModeWidgetError}
        />
      ) : null}
    </>
  );

  const dialogBodyDelivery = (
    <React.Fragment>
      <div className="search-dialog-body">
        {!(searchQuery?.length > MINIMUM_SEARCH_STRING_LENGTH) ? (
          <div
            onClick={handleFetchCurrentLocationClick}
            className="current-location-wrapper"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="current-location-icon">
              <LocationCircleIcon
                className=""
                size={{
                  width: "18px",
                  height: "18px",
                }}
              />
            </div>
            <Typography
              variant="h4"
              weight="regular"
              className="current-location-text"
              fontColor={primaryTextColor}
            >
              {t("header.useCurrentLocation")}
            </Typography>
          </div>
        ) : null}

        {!(searchQuery?.length > MINIMUM_SEARCH_STRING_LENGTH) &&
        (!addresses || (addresses && !addresses.length)) &&
        !savedLocations ? (
          <div className="no-saved-addresses-wrapper">
            <LocationIcon className="choose-location-illustration" />
            <Typography
              variant="h3"
              weight="regular"
              className="choose-location-text"
              fontColor={primaryTextColor}
            >
              {t("header.pleaseSelectYourLocation")}
            </Typography>
          </div>
        ) : null}

        {!!(searchQuery?.length > MINIMUM_SEARCH_STRING_LENGTH) && (
          <div className="search-results-list">
            {places && places.length ? (
              places.map((row) => <SubLocalityItem key={row.id} data={row} />)
            ) : (
              <EmptySearchState
                placeholderText={t("header.noSearchResultsFound")}
              />
            )}
          </div>
        )}

        {addresses &&
        addresses.length &&
        savedLocations &&
        savedLocations.length &&
        !(searchQuery?.length > MINIMUM_SEARCH_STRING_LENGTH) ? (
          <Fragment>
            <div className="saved-address-list">
              {addresses.map((location) => (
                <AddressItem key={location.id} address={location} />
              ))}
              <div className="recent-searches">
                <SearchIcon
                  className="search-icon"
                  fill={COLOR_GUNMETAL_GRAY}
                />
                <Typography
                  variant="h3"
                  weight="semiBold"
                  fontColor={COLOR_GUNMETAL_GRAY}
                >
                  {t("header.recentSearches")}
                </Typography>
              </div>
              {savedLocations.map((location) => (
                <SubLocalityItem
                  showIcon={true}
                  key={location.id}
                  data={location}
                />
              ))}
            </div>
          </Fragment>
        ) : addresses &&
          addresses.length &&
          !(searchQuery?.length > MINIMUM_SEARCH_STRING_LENGTH) ? (
          <div className="saved-address-list">
            {addresses.map((location) => (
              <AddressItem key={location.id} address={location} />
            ))}
          </div>
        ) : savedLocations &&
          savedLocations.length &&
          !(searchQuery?.length > MINIMUM_SEARCH_STRING_LENGTH) ? (
          <div className="saved-address-list">
            <div className="recent-searches">
              <SearchIcon className="search-icon" fill={COLOR_GUNMETAL_GRAY} />
              <Typography
                variant="h3"
                weight="semiBold"
                fontColor={COLOR_GUNMETAL_GRAY}
              >
                {t("header.recentSearches")}
              </Typography>
            </div>

            {savedLocations.map((location, index) => (
              <SubLocalityItem showIcon={true} key={index} data={location} />
            ))}
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );

  const dialogBodyPickup = (
    <React.Fragment>
      <div className="search-dialog-body">
        <Typography
          className={"pickup-stores-heading"}
          variant="para"
          fontColor={COLOR_SLATE_GRAY}
          weight={"bold"}
        >
          {!(searchQuery?.length > MINIMUM_SEARCH_STRING_LENGTH)
            ? t("header.ourStores")
            : t("header.matchingStores")}
        </Typography>
        <div className="pickup-stores-list">
          {pickupStoresLoading && pickupStores.length === 0 ? (
            <Loader loaderColor={primaryColor} size={"40px"} />
          ) : (!(searchQuery?.length > MINIMUM_SEARCH_STRING_LENGTH) &&
              pickupStores?.length === 0) ||
            pickupStores?.length === 0 ? (
            <EmptySearchState
              placeholderText={t("header.noSearchResultsFound")}
            />
          ) : (
            pickupStores
              ?.filter((_, index) => index < 9)
              .map((store) => (
                <PickupStoreItem key={store.biz_location_id} store={store} />
              ))
          )}
        </div>
      </div>

      <div className="search-dialog-footer">
        {storeLocatorPageConfig.enabled && (
          <div
            className="store-locator-link-wrapper"
            onClick={handleGoToStoreLocatorClick}
          >
            <Typography
              variant="h3"
              weight="regular"
              className="store-locator-link"
              fontColor={primaryColor}
            >
              {t("searchLocator.goToStoreLocator")}
            </Typography>
            <ArrowIcon
              fill={primaryColor}
              rotate={i18n.dir() === "rtl" ? "180deg" : "0deg"}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );

  const searchLocationBox = (
    <React.Fragment>
      {children}
      <Divider variant="horizontal" size="100%" color={COLOR_QUICK_SILVER} />
      {(ffMode === "pickup" ? pickupStoresLoading : fetchLocationProgress) && (
        <ColoredLinearProgress />
      )}
      {ffMode === "pickup" ? dialogBodyPickup : dialogBodyDelivery}
    </React.Fragment>
  );

  return (
    <>
      {!landingPage && (
        <div className="location-search-title-wrapper">
          <Typography
            variant="h1"
            weight="bold"
            className="location-search-title"
            fontColor={primaryTextColor}
          >
            {multipleStoreDialog
              ? t("header.selectAStore")
              : deliverNowLater
                ? ffMode === "delivery"
                  ? t("header.selectDeliveryTime")
                  : t("header.selectPickupTime")
                : t("header.searchLocation")}
          </Typography>
          <CrossIcon
            fill="#A3A3A4"
            size={{ width: 16, heigth: 16 }}
            className="close-dialog-icon"
            onClickCallback={handleClose}
          />
        </div>
      )}
      <Divider variant="horizontal" size="99%" color={COLOR_QUICK_SILVER} />
      {!deliverNowLaterCheckout && (
        <FulFilmentTypeSelector
          isDarkHeader={isDarkHeader}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          headerColorConfig={headerColorConfig}
          fulfilmentCallback={fulfilmentCallback}
          fulfilmentType={ffMode}
          secondaryTextColor={secondaryTextColor}
        />
      )}{" "}
      <div className="search-results-wrapper">
        {!multipleStoreDialog && !deliverNowLater && searchLocationBox}
        {isMobileView || deliverNowLaterCheckout ? (
          multipleScreensBody
        ) : (
          <Dialog
            open={multipleStoreDialog || deliverNowLater}
            keepMounted
            onClose={handleClose}
            classes={{
              root: "multiple-store-dialog-root",
              container: "multiple-store-dialog-container",
              paper: multipleStoreDialog
                ? "multiple-store-dialog-paper"
                : "delivery-nowlater-dialog-paper",
            }}
            disableScrollLock={true}
          >
            {multipleScreensBody}
          </Dialog>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    upsdk: state.upsdk,
    showLocationDialog: state.ui.showLocationDialog,
    subLocality: state.ui.subLocality,
    deliveryTime: state.ui.deliveryTime,
    selectedStore: upsdkSelector.getStore(state),
    cart: upsdkSelector.getCart(state),
    fulfilmentType: upsdkSelector.getFullfilment(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSubLocality: setSubLocality,
      setLocationDialog: setLocationDialog,
      unSetLocationDialog: unSetLocationDialog,
      setSelectedAddress: setSelectedAddress,
    },
    dispatch,
  );

export default withTranslation(
  ["translations"],
  translateOptions,
)(connect(mapStateToProps, mapDispatchToProps)(SearchWidget));
