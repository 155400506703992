import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import classNames from "classnames";
import upsdkService from "common/services/upsdkService";
import CrossIcon from "../../../assets/icons/cross.icon";
import SearchIcon from "../../../assets/icons/search.icon";
import googlePlacesService from "common/services/googlePlaces";
import upsdkSelector from "common/store/selectors/upsdkSelector";
import configSelector from "common/store/selectors/configSelector";
import SearchCard from "../../../common/location-search/search-card/index.component";
import { clearSubLocality } from "common/store/actions/ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { translateOptions } from "../../../i18n";
import { setSubLocality } from "common/store/actions/ui";
import { BaseContext } from "../../../context/BaseContext";
import {
  TextField,
  InputAdornment,
  ClickAwayListener,
} from "@material-ui/core";
import { COLOR_BLACK } from "../../../constants/colors.constants";
import { MINIMUM_SEARCH_STRING_LENGTH } from "../../../constants/enums.constants";

import "./index.component.scss";

function HeroSection(props) {
  const [places, setPlaces] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [fullfilmentType, setFullfilmentType] = useState(undefined);

  const [showResults, setShowResults] = useState(false);

  // props
  const { config, upsdk, selectedStore, subLocality, userData } = props;

  // callbacks
  const { t, clearSubLocality } = props;
  const BaseConsumer = useContext(BaseContext);
  const { isMobileView } = BaseConsumer;
  const bizInfo = upsdkSelector.getBizInfo({ upsdk });
  const selectedType = upsdkSelector.getFullfilment({ upsdk });
  const addresses = userData ? userData.addresses : [];

  const handleOnChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    googleAutoComplete(query);
    setLoading(true);
  };

  const handleFocus = (event) => {
    /**
     * @mayank
     * Why is this required? Doesn't seem smooth
     */
    // if (isMobileView) {
    //   // waiting for keyboard to open
    //   setTimeout(() => {
    //     window.scrollTo({
    //       top: event.target.getBoundingClientRect().top,
    //     });
    //   }, 500);
    // }
    setShowResults(true);
  };

  const handleClose = () => {
    setShowResults(false);
  };
  const handleClearSearch = () => {
    setShowResults(false);
    clearSubLocality(null);
    upsdkService.removeStore();
    upsdkService.removeDeliveryAddress();
    setSearchQuery("");
  };

  // eslint-disable-next-line
  const googleAutoComplete = useCallback(
    (_searchVal) => {
      if (_searchVal?.length < MINIMUM_SEARCH_STRING_LENGTH) return;

      setLoading(true);

      googlePlacesService
        .search(_searchVal, bizInfo.isd_code)
        .then((response) => {
          setPlaces(response);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [bizInfo],
  );

  const handleClickAway = () => {
    if (!!searchQuery.length) {
      let value = "";
      if (selectedType === "delivery") {
        if (subLocality) {
          value = subLocality.name || subLocality.tag;
        }
      }
      if (selectedType === "pickup") {
        if (selectedStore) {
          value = selectedStore.name;
        }
      }
      setSearchQuery(value);
      setShowResults(false);
      setPlaces([]);
    } else if (showResults) {
      setShowResults(false);
      setSearchQuery("");
      setPlaces([]);
    } else if (showResults) {
      setShowResults(false);
    }
  };

  // const validateFulfilmentModeChanges = (fulmentType) => {
  //   showDialog({
  //     title: t("header.clearCartHeading"),
  //     description: t("header.clearCartDescription"),
  //     buttonText: t("header.clearCartConfirm"),
  //     buttonColor: primaryColor,
  //     onClickCallback: () => {
  //       upsdkService.clearCart();
  //       upsdkService.removeStore();
  //       upsdkService.removeDeliveryAddress();
  //       clearSubLocality(null);
  //       hideDialog();
  //       setFullfilmentType(fulmentType);
  //       upsdkService.setFulfilmentType(fulmentType);
  //     },
  //   });
  // };

  const fulfilmentCallback = (type) => {
    upsdkService.setFulfilmentType(type.key);
  };

  const renderSearchLocationWidget = () => {
    return (
      <div className="search-location-widget-wrapper">
        {/* <FullfilmentTypeList /> */}
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="search-box-wrapper">
            <TextField
              fullwidth="true"
              variant="filled"
              placeholder={
                selectedType === "delivery"
                  ? t("common.searchAddress")
                  : t("common.selectStore")
              }
              onChange={handleOnChange}
              onFocus={handleFocus}
              value={searchQuery}
              InputProps={{
                // autoFocus: true,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment
                    className="search-start-adornment"
                    position="start"
                  >
                    <SearchIcon
                      className="search-bar-icon"
                      fill={COLOR_BLACK}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className="search-input-adornment"
                  >
                    {searchQuery.length ? (
                      <CrossIcon
                        fill="#000000"
                        size={{ width: 14, heigth: 14 }}
                        className="close-search-icon"
                        onClickCallback={handleClearSearch}
                      />
                    ) : (
                      <></>
                    )}
                  </InputAdornment>
                ),
              }}
              style={
                props?.theme === "theme2"
                  ? {
                      backgroundColor: "#F5F5F5",
                      border: "1px solid #CCCCCC",
                    }
                  : {}
              }
              className={classNames([
                "location-search-box",
                {
                  "no-border-radius": !!showResults,
                  "border-radius": !showResults,
                },
              ])}
            />
            {showResults && (
              <div className="search-result-wrapper">
                <SearchCard
                  {...props}
                  landingPage={true}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  setLoading={setLoading}
                  addresses={addresses}
                  isLoading={isLoading}
                  places={places}
                  handleClose={handleClose}
                  fullfilmentType={fullfilmentType}
                  setOpen={setShowResults}
                  fulfilmentCallback={fulfilmentCallback}
                  isMobileView={isMobileView}
                />
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
    );
  };

  // useEffect(() => {
  //   const fulfilmentTypes = configSelector.getFulfilmentTypes({ config });
  //   setFullfilmentTypeList(fulfilmentTypes);
  // }, [config]);

  useEffect(() => {
    if (!selectedType) {
      const defaultFulfilmentMode = configSelector.getDefaultFulfilmentMode({
        config,
      });
      setFullfilmentType(defaultFulfilmentMode);
      upsdkService.setFulfilmentType(defaultFulfilmentMode);
    } else {
      setFullfilmentType(selectedType);
    }
  }, [selectedType, config]);

  useEffect(() => {
    let value = "";
    if (selectedType === "delivery") {
      //   value = t("common.searchAddress");
      if (subLocality) {
        value = subLocality.name || subLocality.tag;
      }
    }
    if (selectedType === "pickup") {
      //   value = t("common.selectStore");
      if (selectedStore) {
        value = selectedStore.name;
      }
    }
    setSearchQuery(value);
    googleAutoComplete(value);
    setLoading(true);

    // TODO: Need to fix
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subLocality, selectedType, selectedStore]);

  return (
    <div className="hero-section-wrapper">{renderSearchLocationWidget()}</div>
  );
}

function mapStateToProps(state) {
  return {
    upsdk: state.upsdk,
    config: state.config,
    subLocality: state.ui.subLocality,
    userData: state.auth.data,
    cart: upsdkSelector.getCart(state),
    selectedStore: upsdkSelector.getStore(state),
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSubLocality: setSubLocality,
      clearSubLocality: clearSubLocality,
    },
    dispatch,
  );

export default withRouter(
  withTranslation(
    ["translations"],
    translateOptions,
  )(connect(mapStateToProps, mapDispatchToProps)(memo(HeroSection))),
);
